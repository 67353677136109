<script setup lang="ts">
	import { useRouteStore } from "@/store/routeStore";
  import { useLayoutStore } from "~/store/layoutStore";
  import { useTocStore } from "~/store/tocStore";
import { useUserStore } from "~/store/userStore";

	const routeStore = useRouteStore();
  const layoutStore = useLayoutStore();
  const tocStore = useTocStore();
  const userStore = useUserStore();

  const router = useRouter();
  const route = useRoute();

	const routeList = computed(() => {
		return router.options.routes[0].children?.find(v => v.name === 'toc')?.children?.filter(v => v.meta[userStore.getUserType] === true);
	});


  const selectRoute = computed(() => {
    return route.matched.filter((v, index) => index !== 0 && index !== 1);
  })

  async function selectTocMenu(navigate: Function) {
		await navigate();

		let wrapper = document.getElementsByClassName("wrapper")[0];
		let side_menu = document.getElementsByClassName("side_menu")[0];

		if (side_menu.classList.contains("on")) {
			wrapper.classList.add("sideon");
		}
	}


  function goPath(index: number){
    let nowIndex = selectRoute[selectRoute.length - 1].meta.name ? selectRoute.value.length : selectRoute.value.length - 1;
    
    if(index === 1 || index - nowIndex === 0) return;
    
    router.go(index - nowIndex);

  }

</script>

<template>
	<!-- depth menu -->
	<div :class="`${layoutStore.openSidebar === true ? 'sideon' : ''} teb_depthmenu`">
		<div class="menuDepth depth1">
			<ul>
        <span class="tab-header">
          <template v-for="(route, i) in selectRoute">
            <a v-if="i === selectRoute.length - 2 && (!selectRoute[selectRoute.length-1].meta.name || selectRoute[selectRoute.length - 1].meta.name == '')">{{ route.meta.name }}</a>
            <a v-else :class="(i+1) === 1 || (i+1) - (selectRoute.length) === 0 ? '' : 'tab-router'" @click="goPath(i + 1)">{{ `${route.meta.name ? route.meta.name : ''}`  }}</a>
            <a>{{ i !== selectRoute.length - 1 && selectRoute[i+1].meta.name ? ' > ' : ' ' }}</a>
            
          </template>
        </span>
			</ul>
		</div>
	</div>
</template>
.
<style lang="scss" scoped>
  .menuDepth{
    .tab-header{
      font-size: 20px;
      font-weight: bold;
      padding: 17px;
      .tab-router{
        cursor: pointer;
        &:hover{
          background-color: #e3e3e3;
        }
      }
    }
  }

</style>